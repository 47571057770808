import React, { useState } from "react"
import CrossIcon from "../../images/svg/cross.svg"
import EventControllerBtn from "../EventControllerBtn"
import { API_EVENTS } from "../../events"

type Props = {
  nextStep: () => void
}

const DefaultView: React.FC<Props> = ({ nextStep }) => {
  const [showDetails, setShowDetails] = useState(false)
  const [showDisclaimer, setShowDisclaimer] = useState(false)

  const handleToggleDetails = () => {
    setShowDetails(!showDetails)
    setShowDisclaimer(false)
  }
  const handleToggleDisclaimer = () => {
    setShowDisclaimer(!showDisclaimer)
    setShowDetails(false)
  }

  return (
    <div className="flex h-full w-full flex-col items-center justify-end gap-4 pb-4 text-center font-gravity-wide text-[12px] uppercase sm:w-auto lg:gap-12 lg:text-[14px]">
      <div className="flex flex-1 flex-row items-center justify-center gap-4">
          <EventControllerBtn
                eventName={API_EVENTS.SHIRT_DROP}
                eventEndedText="Pre-sale Ended"
                cta={() => nextStep()}
                eventEndedStyles="text-black bg-white"
          >
            <span>Mint Shirt</span>
          </EventControllerBtn>
        {/* <button
          onClick={() =>
            window.open(site.siteMetadata.boneDragon.boneDragonUrl)
          }
          className={defaultButtonClasses}
        >
          SHOES STILL AVAILABLE {">"} HERE
        </button> */}
      </div>
      <div className="relative flex w-full flex-row justify-center gap-4 lg:gap-6">
        <div className="absolute bottom-[50px] left-1/2 flex w-full max-w-[90%] -translate-x-1/2 justify-center text-[10px] font-normal normal-case md:max-w-[900px] md:text-[14px]">
          {showDetails && (
            <div className="pointer-events-auto relative flex w-full flex-col gap-6 rounded-[10px] bg-white/50 p-4 pr-8 text-left font-sans backdrop-blur-lg transition-opacity duration-150 ease-in-out">
              <button
                className="absolute right-4 top-4 appearance-none border-0 bg-transparent p-0"
                onClick={handleToggleDetails}
              >
                <CrossIcon className="h-4 w-4 text-white" />
              </button>
              <h3 className="font-gravity-wide text-[1.2em] font-black uppercase leading-tight">
                Details
              </h3>
              <div className="grid grid-cols-2 gap-4 font-sans text-[8px] lg:text-[12px]">
                <div className="col-span-1 flex flex-col gap-6">
                  <div className="flex flex-col gap-2">
                    <h3 className="font-gravity-wide text-[10px] font-black uppercase leading-tight md:text-[14px]">
                      DROP CONTENTS
                    </h3>
                    <div>
                      <ol className="li:pl-2 list-inside list-disc pl-4 font-sans">
                        <li>
                          T-SHIRT DROP 01 NFT with Utility
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <h3 className="font-gravity-wide text-[10px] font-black uppercase leading-tight md:text-[14px]">
                      PHYSICAL ITEM
                    </h3>
                    <div>
                      <ol className="li:pl-2 list-inside list-disc pl-4 font-sans">
                        <li>
                          T-SHIRT DROP 01
                        </li>
                        <li>
                          OREMOB Star-Tags
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <h3 className="font-gravity-wide text-[10px] font-black uppercase leading-tight md:text-[14px]">
                      UTILITIES
                    </h3>
                    <div>
                      <ol className="li:pl-2 list-inside list-disc pl-4 font-sans">
                        <li>
                          Eligible Voucher for one time physical OREMOB SHIRT.
                        </li>
                        <li>Eligible for guaranteed WL for next OREMOB SHIRT DROP.</li>
                        <li>Forgeable during future Casing Ceremony.</li>
                        <li>Stake to earn $NMKR tokens during Casing Ceremony.</li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="col-span-1 flex flex-col gap-6">
                  <div className="flex flex-col gap-2">
                    <h3 className="font-gravity-wide text-[10px] font-black uppercase leading-tight md:text-[14px]">
                      SPECS
                    </h3>
                    <div>
                      <ol className="li:pl-2 list-inside list-disc pl-4 font-sans">
                        <li>
                          Material: 100% Organic Cotton, 300 GSM, Single Jersey
                        </li>
                        <li>Certification: GOTS Certified – The highest standard in the textile industry</li>
                        <li>Textile Color: Black</li>
                        <li>Origin: Manufactured in Turkey and printed at our Munich facility</li>
                        <li>Fit: Slightly oversized with dropped shoulders</li>
                        <li>Sizes:  S  /  M  /  L  /  XL  /  XXL</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showDisclaimer && (
            <div className="relative flex flex-col gap-[16px] rounded-[10px] bg-white/50 p-4 pr-8 text-left font-sans backdrop-blur-lg transition-opacity duration-150 ease-in-out">
              <button
                className="absolute right-4 top-4 appearance-none border-0 bg-transparent p-0"
                onClick={handleToggleDisclaimer}
              >
                <CrossIcon className="h-4 w-4 text-white" />
              </button>
              <h3 className="font-gravity-wide text-[1.2em] font-black uppercase leading-none tracking-wide">
                Disclaimer:
              </h3>
              <ol className="list-disc pl-4 uppercase leading-tight">
                <li> PRE-SALE AVAILABLE TO OREMOB HOLDERS </li>
                <li>
                  <span>
                    DISCOUNTS FOR HOLDERS OF
                  </span>
                  <ul className="list-none pl-2">
                    <li>- &nbsp;SABUKARU X OREMOB Drop 01
                    by specifically holding the SXO BULLET ARTIFACT → 25%</li>
                    <li>- &nbsp;OREMOB 10K ENCASED → 25%</li>
                    <li>- &nbsp;BURNERPHONE OG → 15%</li>
                    <li>- &nbsp;BURNERPHONE RING OG → 15%</li>
                    <li>- &nbsp;BURNERPHONE RING → 15%</li>
                    <li>- &nbsp;BURNERPHONE EMBLEM → 15%</li>
                    <li>- &nbsp;OREMOB 10K PFP COLLECTION → 10%</li>
                  </ul>
                </li>
                <li>KYC DETAILS REQUIRED FOR SHIPPING VIA VAULT3 VIA AN ASSET-GATED FORM LATER</li>
                <li>PHYSICAL ASSETS ARE LIMITED & SUBJECT TO AVAILABILITY</li>
                <li>NO GUARANTEE OF SHIRT BEING AVAILABLE IN YOUR SIZE</li>
                <li>SALE OPEN FOR 48HRS</li>
                <li>YOU MAY PURCHASE MULTIPLE SHIRTS</li>
                <li>1 MINT PER TX, NO LIMIT ON PURCHASES</li>
                <li>⁠OWNING THE OREMOB SHIRT DROP 01 NFT WILL GUARANTEE THE SAME SIZE ON THE NEXT SHIRT DROP WITHIN A 6-HOUR WINDOW DURING MINT</li>
                <li>OWNING THE OREMOB SHIRT DROP 01 NFT CAN PROVIDE DISCOUNTS OF UP TO 30%</li>
              </ol>
            </div>
          )}
        </div>
        <button
          className={`flex w-[160px] items-center justify-center justify-self-end rounded-full py-2 px-4 uppercase leading-normal text-black transition-colors duration-200 ease-in-out hover:bg-green hover:text-black focus:text-black lg:w-[220px] ${
            showDetails ? "bg-green" : "bg-white"
          }`}
          onClick={handleToggleDetails}
        >
          Details
        </button>
        <button
          className={`flex w-[160px] items-center justify-center rounded-full py-2 px-4 uppercase leading-normal text-black transition-colors duration-200 ease-in-out hover:bg-green hover:text-black focus:text-black lg:w-[220px] ${
            showDisclaimer ? "bg-green" : "bg-white"
          }`}
          onClick={handleToggleDisclaimer}
        >
          Disclaimer
        </button>
      </div>
    </div>
  )
}

export default DefaultView
